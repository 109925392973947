<template>
  <section
    :class="[
      'banner-section',
      {
        variant2: isVariant2,
        'top-banner': isTopBanner,
        extended: extendCaptionWidth
      }
    ]"
  >
    <div class="container">
      <div class="row">
        <div
          :class="[
            'col-12 col-md-5 banner-left',
            extendCaptionWidth ? 'col-lg-5' : 'col-lg-4'
          ]"
        >
          <div class="banner-information">
            <i18next
              :path="bannerTitle"
              tag="div"
              class="banner-title2"
              v-if="isVariant2"
            >
              <span>{{ bannerHighlightTitle }}</span>
            </i18next>
            <div class="banner-title" v-else>
              {{ bannerTitle }}
            </div>
            <div class="banner-content">
              {{ bannerContent }}
            </div>
            <div class="banner-btn" v-if="bannerBtn">
              <div class="coming__soon--btn" v-if="comingSoonBtn">
                {{ $t("general.coming_soon") }}...
              </div>
              <div class="btn" type="button" @click="onClick" v-else>
                {{ bannerBtnText }}
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[
            'col-12 col-md-7 banner-right',
            extendCaptionWidth ? 'col-lg-7' : 'col-lg-8'
          ]"
        >
          <div class="banner-image">
            <img
              :src="bannerDesktopImage"
              alt="banner"
              class="d-none d-lg-block"
              :style="
                bannerDesktopCustomHeight
                  ? `max-height: ${bannerDesktopCustomHeight}px`
                  : ''
              "
            />
            <img
              :src="bannerMobileImage ? bannerMobileImage : bannerDesktopImage"
              alt="banner"
              class="d-block d-lg-none"
              :style="
                bannerMobileCustomHeight
                  ? `max-height: ${bannerMobileCustomHeight}px`
                  : ''
              "
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    isTopBanner: {
      type: Boolean,
      default: true
    },
    isVariant2: {
      type: Boolean,
      default: false
    },
    bannerTitle: String,
    bannerHighlightTitle: String,
    bannerContent: String,
    bannerBtnText: {
      type: String,
      default: ""
    },
    bannerDesktopImage: {
      type: String,
      default: ""
    },
    bannerMobileImage: {
      type: String,
      default: ""
    },
    bannerDesktopCustomHeight: {
      type: String,
      default: ""
    },
    bannerMobileCustomHeight: {
      type: String,
      default: ""
    },
    bannerBtn: {
      type: Boolean,
      default: false
    },
    comingSoonBtn: {
      type: Boolean,
      default: false
    },
    extendCaptionWidth: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-section {
  background: $radial-gradient-bg;
  font-family: $font-family;
  text-align: left;
  .banner-left {
    @include flex-center;
    position: relative;
  }
  .banner-right {
    height: 536px;
    @include flex-align-end;
  }
  .banner-image {
    max-height: 100%;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .banner-information {
    @include flex-column-start;
    .banner-title {
      @include title-large;
      color: $brand-neutral-0;
    }
    .banner-title2 {
      @include title-extra-large;
      color: $brand-primary-75;
      span {
        display: block;
        font-weight: 600;
        color: $brand-neutral-0;
      }
    }
    .banner-content {
      margin-top: 8px;
      @include subtitle-regular;
      font-weight: 400;
      color: $brand-neutral-0;
    }
    .banner-btn {
      margin-top: 24px;
      .coming__soon--btn {
        border-radius: 8px;
        padding: 4px 8px;
        @include body-medium;
        background: linear-gradient(
          63deg,
          $brand-secondary1-400 27.16%,
          $brand-primary-400 71.03%
        );
        color: $brand-neutral-0;
      }
      .btn {
        padding: 16px 32px;
        border-radius: 100px;
        color: $brand-primary-400;
        background-color: $brand-primary-100;
        @include subtitle-regular;
        line-height: 21px;
        letter-spacing: 1.25px;
        cursor: pointer;
      }
    }
  }
  &.variant2 {
    background: $brand-primary;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .banner-information {
      .banner-content {
        @include h6;
        color: $brand-primary-50;
      }
      .banner-btn {
        .btn {
          color: $brand-primary-50;
          background-color: $brand-primary-400;
        }
      }
    }
  }
  &.extended {
    .banner-right {
      height: 480px;
    }
    .banner-information {
      .banner-title {
        font-size: 36px;
        line-height: 43.2px;
        font-weight: 500;
      }
      .banner-content {
        margin-top: 16px;
        font-size: 24px;
        line-height: 32.8px;
        font-weight: 400;
        letter-spacing: 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .banner-section {
    .banner-information {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
    }
    &.top-banner {
      .banner-information {
        padding-top: 64px;
      }
    }
    &.variant2 {
      .banner-information {
        width: calc(100% + 20px);
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-section {
    .banner-information {
      top: 48%;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .banner-section {
    .banner-left {
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .banner-section {
    .banner-right {
      height: 430px;
    }
    .banner-information {
      .banner-title {
        @include h6;
      }
      .banner-title2 {
        @include h3;
        color: $brand-primary-75;
        span {
          color: $brand-neutral-0;
        }
      }
      .banner-content {
        @include body-regular($brand-neutral-0);
      }
    }
    &.variant2 {
      .banner-information {
        .banner-content {
          @include subtitle-regular;
          font-weight: 400;
        }
      }
    }
    &.extended {
      .banner-right {
        height: 384px;
      }
      .banner-information {
        .banner-title {
          font-size: 28px;
          line-height: 35px;
          font-weight: 400;
          letter-spacing: 0.07px;
        }
        .banner-content {
          font-size: 18px;
          line-height: 27px;
          font-weight: 400;
          letter-spacing: 0.1px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .banner-section {
    .banner-right {
      height: 346px;
    }
    .banner-information {
      .banner-btn {
        margin-top: 16px;
      }
    }
    &.variant2 {
      .banner-right {
        height: 302px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .banner-section {
    .banner-right {
      height: 150px;
      padding-left: 0;
      padding-right: 0;
      position: relative;
      .banner-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        max-width: 360px;
        margin: auto;
        img {
          width: 100%;
          object-position: top;
          object-fit: cover;
        }
      }
    }
    .banner-information {
      padding: 80px 0px 16px;
      align-items: center;
      text-align: center;
    }
    &.variant2 {
      .banner-right {
        height: 280px;
      }
      .banner-information {
        padding: 64px 0px 0px;
      }
    }
    &.extended {
      .banner-right {
        height: 288px;
      }
    }
  }
}
</style>
