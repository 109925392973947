<template>
  <Banner
    :bannerTitle="bannerTitle"
    :bannerHighlightTitle="bannerHighlightTitle"
    :bannerContent="bannerContent"
    :bannerBtnText="bannerBtnText"
    :bannerDesktopImage="bannerDesktopImage"
    :bannerBtn="true"
    :comingSoonBtn="true"
    :isVariant2="true"
    :isTopBanner="false"
  />
</template>

<script>
import Banner from "./Banner.vue";
export default {
  components: { Banner },
  data() {
    return {
      bannerTitle: this.$t("landing_page.learn_more_section.title"),
      bannerHighlightTitle: this.$t("landing_page.learn_more_section.title_0"),
      bannerContent: this.$t("landing_page.learn_more_section.content"),
      bannerBtnText: this.$t("partner.button.learn_more"),
      bannerDesktopImage: require("@/assets/images/landing/banner/learn-more-banner.png")
    };
  }
};
</script>
