var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[
    'banner-section',
    {
      variant2: _vm.isVariant2,
      'top-banner': _vm.isTopBanner,
      extended: _vm.extendCaptionWidth
    }
  ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{class:[
          'col-12 col-md-5 banner-left',
          _vm.extendCaptionWidth ? 'col-lg-5' : 'col-lg-4'
        ]},[_c('div',{staticClass:"banner-information"},[(_vm.isVariant2)?_c('i18next',{staticClass:"banner-title2",attrs:{"path":_vm.bannerTitle,"tag":"div"}},[_c('span',[_vm._v(_vm._s(_vm.bannerHighlightTitle))])]):_c('div',{staticClass:"banner-title"},[_vm._v(" "+_vm._s(_vm.bannerTitle)+" ")]),_c('div',{staticClass:"banner-content"},[_vm._v(" "+_vm._s(_vm.bannerContent)+" ")]),(_vm.bannerBtn)?_c('div',{staticClass:"banner-btn"},[(_vm.comingSoonBtn)?_c('div',{staticClass:"coming__soon--btn"},[_vm._v(" "+_vm._s(_vm.$t("general.coming_soon"))+"... ")]):_c('div',{staticClass:"btn",attrs:{"type":"button"},on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(_vm.bannerBtnText)+" ")])]):_vm._e()],1)]),_c('div',{class:[
          'col-12 col-md-7 banner-right',
          _vm.extendCaptionWidth ? 'col-lg-7' : 'col-lg-8'
        ]},[_c('div',{staticClass:"banner-image"},[_c('img',{staticClass:"d-none d-lg-block",style:(_vm.bannerDesktopCustomHeight
                ? `max-height: ${_vm.bannerDesktopCustomHeight}px`
                : ''),attrs:{"src":_vm.bannerDesktopImage,"alt":"banner"}}),_c('img',{staticClass:"d-block d-lg-none",style:(_vm.bannerMobileCustomHeight
                ? `max-height: ${_vm.bannerMobileCustomHeight}px`
                : ''),attrs:{"src":_vm.bannerMobileImage ? _vm.bannerMobileImage : _vm.bannerDesktopImage,"alt":"banner"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }